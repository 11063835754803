import React from 'react';

const NotFound = () => (
  <div>
    <div className="container">
      <h1>404 - The page you are looking for was not found!</h1>
    </div>
  </div>
);

export default NotFound;
